iframe {
  display: none;
}

.pf-c-login__header {
  display: flex !important;
  background: transparent !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.pf-c-login__header img {
  max-width: calc(100% - 32px) !important;
}

/* .ReactCodeInput input {
  text-security: disc;
  -webkit-text-security: disc;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiTableCell-head * {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

body.Notifications .MuiModal-root div[class*="MUIDataTableFilter-root"] .MuiGrid-container .MuiGrid-item:first-child,
body.Segments .MuiModal-root div[class*="MUIDataTableFilter-root"] .MuiGrid-container .MuiGrid-item:last-child {
  max-width: 100% !important;
  flex-basis: 100% !important;
}

.pf-c-background-image::before {
  background-position: center;
}

button.MuiButton-root {
  text-transform: capitalize !important;
}

.showMoreSub:hover {
  text-decoration: underline;
  color: #060cee !important;
}

.styleTextFooter {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

li .MuiListItemText-multiline p.MuiListItemText-secondary {
  white-space: normal;
  word-break: break-word;
}

.MuiFormGroup-root .MuiFormControlLabel-root {
  max-width: fit-content !important;
}

/* .MuiInputLabel-root{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  height: 100%;
} */
.MuiInputLabel-shrink {
  width: 120%;
  max-width: 120%;
}

.reivewContainer .MuiGrid-root.MuiGrid-item {
  display: flex;
  align-items: flex-end;
}

.reivewContainer .MuiFormLabel-filled:not(.MuiInputLabel-outlined) {
  position: relative;
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
}

.reivewContainer .MuiFormLabel-filled:not(.MuiInputLabel-outlined)+.MuiInput-root {
  margin-top: 0;
}

.addressContainer .MuiFormControlLabel-root {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}

.addressContainer .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 1rem;
}

.textStatic {
  color: #65748B;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  text-align: left;
  /* margin-top: 3px; */
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  padding-top: 0;
}

.MuiPopover-paper {
  overscroll-behavior: contain;
}

.selectField .MuiInputLabel-root.MuiFormLabel-root {
  max-width: calc(100% -40px);
}